/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { GatsbyBrowser } from 'gatsby';
import '../styles/index.scss';
import '../templates/index.scss';
import React from 'react';
import Nav from '../components/nav/nav';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';

// after calling LogRocket.init()
// LogRocket.init('kfpyxm/edge-fx');
// setupLogRocketReact(LogRocket);

export const gatsbyBrowser: GatsbyBrowser = {
  // we wrap the root element in a Update Wrapper, that calls the window resize callbacks
  // until the viewport size is available as we typically start with viewport "none" in SSR
  // also rerenders the app whenever the viewport changes
  wrapRootElement: ({ element }) => {
    return <Nav>{element}</Nav>;
  },
  // onClientEntry: () => {
  //   window.history.replaceState(
  //     {},
  //     document.title,
  //     `/${window.location.pathname.replace('/', '')}`
  //   );
  // },
  // onRouteUpdate: ({ location }) => {
  //   window.history.replaceState(
  //     {},
  //     document.title,
  //     `/${location.pathname.replace('/', '')}`
  //   );
  // },
  shouldUpdateScroll: () => {
    return false;
  },
};
