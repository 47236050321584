// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/About.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-full-page-video-tsx": () => import("./../../../src/templates/FullPageVideo.tsx" /* webpackChunkName: "component---src-templates-full-page-video-tsx" */),
  "component---src-templates-gallery-tsx": () => import("./../../../src/templates/Gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */),
  "component---src-templates-intro-tsx": () => import("./../../../src/templates/Intro.tsx" /* webpackChunkName: "component---src-templates-intro-tsx" */),
  "component---src-templates-project-detail-tsx": () => import("./../../../src/templates/ProjectDetail.tsx" /* webpackChunkName: "component---src-templates-project-detail-tsx" */)
}

