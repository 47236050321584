import React, { useRef } from 'react';
import { useTransition, useChain, animated, config } from 'react-spring';
import './sidebar.scss';
import { navigate } from 'gatsby';
import { withNavigationContext } from '../../navigation';
import { SharingIcons } from '../SharingIcons';

export const Sidebar = withNavigationContext(
  ({ fullpage, show, toggleMenu }: any) => {
    const sidebarRef = useRef() as React.RefObject<any>;
    const transition = useTransition(show, null, {
      from: {
        transform: 'translateX(100%)',
      },
      enter: {
        transform: 'translateX(0)',
      },
      leave: {
        transform: 'translateX(100%)',
      },
      unique: true,
      ref: sidebarRef,
      config: {
        duration: 150,
      },
    });

    const items = ['Home', 'Reel', 'Commercial', 'Gallery', 'About'];

    const itemsRef = useRef() as React.RefObject<any>;
    const trail = useTransition(show ? items : [], (item) => item, {
      from: {
        opacity: 0,
        transform: 'translateY(50px)',
      },
      enter: {
        opacity: 1,
        transform: 'translateY(0)',
      },
      leave: {
        opacity: 0,
        transform: 'translateY(-25px)',
      },
      ref: itemsRef,
      config: { ...config.wobbly, duration: 150 },
      trail: 0,
      unique: true,
    });

    useChain(
      show ? [sidebarRef, itemsRef] : [itemsRef, sidebarRef],
      show ? [0, 0.25] : [0, 0.6]
    );
    return (
      <>
        {transition.map(({ item, key, props }) =>
          item ? (
            <animated.div
              key={key}
              style={props}
              className="sidebar"
              onClick={(e) => {
                toggleMenu();
              }}
            >
              <div
                className={'sidebar-container'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              <div className={'sidebar-content'}>
                {trail.map(({ item, key, props }) => (
                  <h4
                    key={item}
                    style={props}
                    className="sidebar__item"
                    onClick={() => {
                      toggleMenu();
                      setTimeout(() => {
                        navigate(
                          `/${
                            location.pathname
                              .split('/')
                              .some((el) => el === 'en')
                              ? 'en/'
                              : ''
                          }${
                            item.toLowerCase() === 'home'
                              ? ''
                              : item.replace(' ', '-').toLowerCase()
                          }`
                        );
                      }, 500);
                    }}
                  >
                    {item}
                  </h4>
                ))}
                <SharingIcons />
              </div>
            </animated.div>
          ) : null
        )}
      </>
    );
  }
);
