import React from 'react';
import './nav.scss';
import { Icon } from '../menu/menu';
import Helmet from 'react-helmet';

const Nav = ({ children }: any) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edge Fx</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      {children}
    </>
  );
};

export default Nav;
