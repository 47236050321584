import React from 'react';

export const SharingIcons = ({ about }: { about?: boolean }) => {
  return (
    <div
      className={`sharing-icons-container ${
        about ? 'sharing-icons-container-about' : ''
      }`}
    >
      <a href={`https://www.facebook.com/edgefxtstudio`} target="_blank">
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 96.227 96.227"
          xmlSpace="preserve"
        >
          <g>
            <path
              d="M73.099,15.973l-9.058,0.004c-7.102,0-8.477,3.375-8.477,8.328v10.921h16.938l-0.006,17.106H55.564v43.895H37.897V52.332
		h-14.77V35.226h14.77V22.612C37.897,7.972,46.84,0,59.9,0L73.1,0.021L73.099,15.973L73.099,15.973z"
            />
          </g>
        </svg>
      </a>
      <a target="_blank" href={'https://www.instagram.com/edge.f.x/'}>
        <svg width="97.395px" height="97.395px" viewBox="0 0 97.395 97.395">
          <g>
            <path
              d="M12.501,0h72.393c6.875,0,12.5,5.09,12.5,12.5v72.395c0,7.41-5.625,12.5-12.5,12.5H12.501C5.624,97.395,0,92.305,0,84.895
		V12.5C0,5.09,5.624,0,12.501,0L12.501,0z M70.948,10.821c-2.412,0-4.383,1.972-4.383,4.385v10.495c0,2.412,1.971,4.385,4.383,4.385
		h11.008c2.412,0,4.385-1.973,4.385-4.385V15.206c0-2.413-1.973-4.385-4.385-4.385H70.948L70.948,10.821z M86.387,41.188h-8.572
		c0.811,2.648,1.25,5.453,1.25,8.355c0,16.2-13.556,29.332-30.275,29.332c-16.718,0-30.272-13.132-30.272-29.332
		c0-2.904,0.438-5.708,1.25-8.355h-8.945v41.141c0,2.129,1.742,3.872,3.872,3.872h67.822c2.13,0,3.872-1.742,3.872-3.872V41.188
		H86.387z M48.789,29.533c-10.802,0-19.56,8.485-19.56,18.953c0,10.468,8.758,18.953,19.56,18.953
		c10.803,0,19.562-8.485,19.562-18.953C68.351,38.018,59.593,29.533,48.789,29.533z"
            />
          </g>
        </svg>
      </a>
      <a href={`https://vimeo.com/edgefxstudio`} target="_blank">
        <svg width="96.875px" height="96.875px" viewBox="0 0 96.875 96.875">
          <g>
            <path
              d="M97.05,25.977c-0.431,9.453-7.038,22.401-19.806,38.834C64.046,81.975,52.876,90.56,43.734,90.56
		c-5.655,0-10.444-5.226-14.357-15.684c-2.613-9.585-5.227-19.161-7.838-28.746c-2.902-10.452-6.017-15.683-9.352-15.683
		c-0.724,0-3.27,1.531-7.622,4.577L0,29.136c4.788-4.208,9.517-8.422,14.167-12.643c6.394-5.52,11.19-8.429,14.391-8.722
		c7.557-0.725,12.208,4.446,13.954,15.502c1.886,11.938,3.191,19.361,3.922,22.264c2.176,9.902,4.576,14.849,7.19,14.849
		c2.034,0,5.091-3.216,9.159-9.634c4.065-6.427,6.244-11.315,6.537-14.667c0.58-5.545-1.6-8.324-6.537-8.324
		c-2.328,0-4.727,0.531-7.188,1.592c4.77-15.632,13.887-23.229,27.348-22.8C92.923,6.847,97.623,13.322,97.05,25.977z"
            />
          </g>
        </svg>
      </a>
      <a href={`https://www.linkedin.com/company/edgefxstudio`} target="_blank">
        <svg width="96.875px" height="96.875px" viewBox="0 0 96.875 96.875">
          <g>
            <g>
              <path
                d="M11.185,0.08C5.004,0.08,0.001,5.092,0,11.259c0,6.173,5.003,11.184,11.186,11.184c6.166,0,11.176-5.011,11.176-11.184
			C22.362,5.091,17.351,0.08,11.185,0.08z"
              />
              <rect x="1.538" y="30.926" width="19.287" height="62.054" />
              <path
                d="M69.925,29.383c-9.382,0-15.673,5.144-18.248,10.022h-0.258v-8.479H32.921H32.92v62.053h19.27V62.281
			c0-8.093,1.541-15.932,11.575-15.932c9.89,0,10.022,9.256,10.022,16.451v30.178H93.06V58.942
			C93.06,42.235,89.455,29.383,69.925,29.383z"
              />
            </g>
          </g>
        </svg>
      </a>
      <a href={'https://twitter.com/edgefxstudio'} target="_blank">
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
        </svg>
      </a>

      <a href={'https://www.twitch.tv/edge_fx'} target="_blank">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M2.149 0l-1.612 4.119v16.836h5.731v3.045h3.224l3.045-3.045h4.657l6.269-6.269v-14.686h-21.314zm19.164 13.612l-3.582 3.582h-5.731l-3.045 3.045v-3.045h-4.836v-15.045h17.194v11.463zm-3.582-7.343v6.262h-2.149v-6.262h2.149zm-5.731 0v6.262h-2.149v-6.262h2.149z"
            fill-rule="evenodd"
            clip-rule="evenodd"
          />
        </svg>
      </a>
    </div>
  );
};
