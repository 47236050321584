import React from 'react';
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions';
import { Icon } from '../components/menu/menu';
import CookieConsent from 'react-cookie-consent';

const Layout = ({ location, children }: any) => {
  return (
    <TransitionProvider
      location={location}
      mode="immediate"
      enter={{
        opacity: 0.5,
        // transform: 'translate3d(0,20vh,0) scale3d(1, 1, 1) rotate(0deg)',
        config: {
          mass: 10,
          tension: 110,
          friction: 20,
          clamp: true,
        },
      }}
      usual={{
        opacity: 1,
        // transform: 'translate3d(0vh,0vh,0) scale3d(1, 1, 1) rotate(0deg)',
      }}
      leave={{
        opacity: 0.5,
        // transform: 'translate3d(0vh,0vh,0) scale3d(2, 2, 1) rotate(180deg)',
        config: {
          duration: 100,
          clamp: true,
        },
      }}
    >
      <Icon />
      <TransitionViews>{children}</TransitionViews>
      <CookieConsent
        location="bottom"
        buttonText={
          location.pathname.includes('en') ? 'I understand' : 'Accetto'
        }
        buttonStyle={{ background: 'white' }}
        declineButtonStyle={{ background: 'white', color: 'black' }}
        contentStyle={{ backgorund: '#1b1b1b' }}
        declineButtonText={
          location.pathname.includes('en') ? 'Decline' : 'Rifiuto'
        }
        // enableDeclineButton
        flipButtons
        cookieName="gatsby-gdpr-google-analytics"
      >
        {location.pathname.includes('en')
          ? 'This website uses third party cookies cookies to enhance the user experience.'
          : 'Questo sito fa uso di cookie per migliorare l’esperienza di navigazione degli utenti e per raccogliere informazioni sull’utilizzo del sito stesso.'}
      </CookieConsent>
    </TransitionProvider>
  );
};

export default Layout;
