import React, { useState, useCallback } from 'react';
import { Sidebar } from '../sidebar/sidebar';
import './index.scss';
import Burger from '@animated-burgers/burger-slip';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

// don't forget the styles
require('./burger.scss');

export const Icon = () => {
  const [open, setOpen] = useState(false);
  const toggleMenu = useCallback(
    (e: any) => {
      setOpen(!open);
      setSidebar(!sidebar);
    },
    [open]
  );

  const [sidebar, setSidebar] = useState(false);
  const location = useLocation();

  return (
    <>
      {open && (
        <Link
          to={`${
            location.pathname.split('/').some((el) => el === 'en')
              ? (() => {
                  const [s, t, p] = location.pathname.split('/');

                  return `/${p}`;
                })()
              : `/en${location.pathname}`
          }`}
        >
          <div
            className={`language-switch ${
              sidebar ? 'language-switch-dark' : 'language-switch-light'
            }`}
          >
            {location.pathname.split('/').some((el) => el === 'en')
              ? 'EN'
              : 'IT'}
          </div>
        </Link>
      )}

      <Sidebar show={sidebar} key="sidebar" toggleMenu={toggleMenu} />
      <div className={`menu cross menu--2 ${open ? 'dark' : ''}`}>
        <Burger isOpen={open} onClick={toggleMenu} />
      </div>
    </>
  );
};
